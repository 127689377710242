import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import { MaterialModule } from './material.module';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field';
import { AppComponent } from './app.component';
import { HeaderComponent } from './navigation/header/header.component';
import { SidenavListComponent } from './navigation/sidenav-list/sidenav-list.component';

import { LoginComponent } from './auth/login/login.component';
import { AuthService } from './auth/services/auth.service';
import { KeyValuePairService } from './shared/services/keyvaluepair.servce';
import { TokenInterceptorService } from './auth/services/token-interceptor.service';
import { RegisterComponent } from './auth/register/register.component';
import { CreateComponent } from './country/create/create.component';
import { ErrorInterceptorService } from './auth/services/error-interceptor.service';
import { ProgressLoaderInterceptor } from './auth/services/progress-loader-interceptor.service';
import { ToastrModule } from 'ngx-toastr';
import { DialogService } from './shared/services/dialog.services';
import { ConfirmdialogComponent } from './confirmdialog/confirmdialog.component';
import { ErrordialogComponent } from './errordialog/errordialog.component';
import { ErrorDialogService } from './shared/services/errordialog.service';
import { SuccessdialogComponent } from './successdialog/successdialog.component';
import { SuccessDialogService } from './shared/services/successdialog.service';
import { LandingComponent } from './landing/landing.component';
import { ProgressComponent } from './navigation/progress/progress.component';
import { ProgressLoaderService } from './auth/services/progress-loader.service';
import { AgentComponent } from './agentfile/agentfile.component';
import { AgentFileUploadComponent } from './agentfile/file-upload/file-upload.component';

@NgModule({
  declarations: [
    AppComponent ,
    HeaderComponent,
    LoginComponent,
    RegisterComponent,
    CreateComponent,
    AgentComponent,
    ConfirmdialogComponent,
    ErrordialogComponent,
    SuccessdialogComponent,
    SidenavListComponent,
    LandingComponent,
    ProgressComponent,
    AgentFileUploadComponent
  ],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    MaterialModule,
    ToastrModule.forRoot(),
  ],
  providers: [
     AuthService,
     KeyValuePairService,
     {
      provide: HTTP_INTERCEPTORS,
      useClass: ProgressLoaderInterceptor,
      multi: true
    },
     {
       provide: HTTP_INTERCEPTORS,
       useClass: TokenInterceptorService,
       multi: true
     },
     {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptorService,
      multi: true
    },
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, 
      useValue: { appearance: 'fill' 
    }},
    DialogService,
    ErrorDialogService,
    SuccessDialogService,
    ProgressLoaderService
  ],
  entryComponents:[ConfirmdialogComponent,ErrordialogComponent,SuccessdialogComponent],
  bootstrap: [AppComponent]
})
export class AppModule { }
platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

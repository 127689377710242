import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ProgressLoaderService } from 'src/app/auth/services/progress-loader.service';
@Component({
  selector: 'app-progress',
  templateUrl: './progress.component.html',
  styleUrls: ['./progress.component.css']
})
export class ProgressComponent implements OnInit, OnDestroy {
  isLoading = false;
  progressSubscription : Subscription;
  constructor(private progressLoaderService: ProgressLoaderService) { }

  ngOnInit() {
     this.progressSubscription = this.progressLoaderService.isLoading.subscribe(progress => {
        this.isLoading = progress
     } );
  }
  ngOnDestroy() {
    this.progressSubscription.unsubscribe();
  }

}
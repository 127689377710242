<div class="form-container">
    <mat-card  class="form-container">
        <mat-card-header class="form-container">
            <div mat-card-avatar ></div>
            <mat-card-title>CONVERGE ICT</mat-card-title>
            <mat-card-subtitle>Login</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content class="form-container">

            <form  class="login-form" [formGroup]="loginForm" (ngSubmit)="login()">
                <mat-form-field class="full-width" appearance="outline">
                    <mat-label>User Name</mat-label>
                    <input matInput formControlName="emailAddress" placeholder="juandelacruz@convergeict.com" #input [value]="input.value.toUpperCase()"  autocomplete="off">
                </mat-form-field>

                <mat-form-field class="full-width" appearance="outline">
                    <mat-label>Password</mat-label>
                    <input matInput type="password" formControlName="password" autocomplete="off" [type]="hide ? 'password' : 'text'">
                    <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                </mat-form-field>
                <mat-spinner diameter="40" *ngIf="isLoading"></mat-spinner>
                <div>
                    <button mat-flat-button color="primary"  class="button-spacer"> Submit </button>
                </div>

            </form>

        </mat-card-content>
    </mat-card>
</div>
import { Component} from '@angular/core';  
 @Component({  
  selector: 'app-gent',  
  templateUrl: './agentfile.component.html',  
  styleUrls: ['./agentfile.component.css']  
})  
  
export class AgentComponent {  
  isCompleted = false;
  mess = "";
  onFileComplete(data: any) {
    this.isCompleted = true;
    this.mess = data.message;
    setTimeout(() => {
      this.mess = ""
      this.isCompleted = false;
    }, 7000);
  }
} 

import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { ErrorDialogService } from 'src/app/shared/services/errordialog.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorInterceptorService implements  HttpInterceptor {

  constructor(private toastrService: ToastrService, private errorDialogService: ErrorDialogService) { }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      retry(1),
      catchError((err) => {
       let message = ""
        if (err.status === 401) {
          message = "Token has expired or you should be logged in"
        }
        else if (err.status === 404) {
          message = "404 Error, Server Not Found.."
        }
        else if (err.status === 400) {
          message = "400 - Bad Request."
        }
        else if (err.status === 500) {
          message = err.error.message;
        }
        else {
         message = "Unexpected error, Server Process request cannot be made."
        }
        console.log(err);
        
          this.errorDialogService.openErrorDialog(message);
          return throwError(err)
      })
    )
  }
}

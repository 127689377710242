import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { finalize } from "rxjs/operators";
import { ProgressLoaderService } from "./progress-loader.service";

@Injectable() 
export class ProgressLoaderInterceptor implements HttpInterceptor {
    constructor(public proglessLoaderService: ProgressLoaderService) {}
    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        this.proglessLoaderService.show()
        return next.handle(req).pipe(finalize(() => this.proglessLoaderService.hide()));
    }
}
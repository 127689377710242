import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { JoWebuserRolesVm, ProvinceMunicipality } from 'src/app/shared/services/keyvaluepair.servce';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  authChange = new Subject<boolean>();
  isAuthentic = false;
  isCrew = new Subject<boolean>();
  isLineman = new Subject<boolean>();
  isJoAdmin = new Subject<boolean>();
  isAdmin = new Subject<boolean>();
  isContractor = new Subject<boolean>();


  // for userRoles
  isJoReassign = new Subject<boolean>();
  isJoReassignToCrew = new Subject<boolean>();
  isJoReassignToCrewApplyMl = new Subject<boolean>();
  isJoRelease = new Subject<boolean>();
  isJoClose = new Subject<boolean>();
  isJoChangeNnP = new Subject<boolean>();
  isJoRemarks = new Subject<boolean>();
  isJoReSchedule = new Subject<boolean>();
  isActivateCpe = new Subject<boolean>();
  isTerminateOnt = new Subject<boolean>();
  isAssignCpe = new Subject<boolean>();
  isUserRights = new Subject<boolean>();
  isNewUser = new Subject<boolean>();
  isViewUsers = new Subject<boolean>();
  isServiceCall = new Subject<boolean>();
  isNapLocAssignment = new Subject<boolean>();
  isBulkTerminate = new Subject<boolean>();
  isTDLifting = new Subject<boolean>();
  
  isSystemRole = new Subject<boolean>();
  isModule = new Subject<boolean>();
  isRolemodule = new Subject<boolean>();
  isUserModule = new Subject<boolean>();
  isReports = new Subject<boolean>();
  isMaterialUsageReport = new Subject<boolean>();
  isViewJoDetail = new Subject<boolean>();
  isViewNaps = new Subject<boolean>();
  isOtrsViewer = new Subject<boolean>();
  isRefreshCpe = new Subject<boolean>();



  companyName = new Subject<any>();
  currentUser = new Subject<string>();
  defaultCompanyCode = new Subject<string>();
  joWebuserRolesVm = new Subject<JoWebuserRolesVm[]>();
  defaultRetrieveRecord = '1000';
  sharedProvinceAndMunicipality: ProvinceMunicipality[] = [];

  private loginPath = environment.apiUrl + 'jomobileuser/login';
  private registerPath = environment.apiUrl + 'register';
  private modulePath = environment.apiUrl + 'jowebmodules/getmodulename?id=';
  private modulePathRegistration = environment.apiUrl + 'jowebmodules';
  private moduleUserModule = environment.apiUrl + 'JoWebUserModules/UserModules';
  constructor(private http: HttpClient, private router: Router) { }

  login(data: LoginInterface): Observable<any> {
    data.password = this.encrypt(data.password, "unicode")
    return this.http.post(this.loginPath, data)
  }

  register(data): Observable<any> {
    return this.http.post(this.registerPath, data)
  }

  checkRegisteredModule(data): Observable<any> {
    return this.http.get(this.modulePath+data);
  }

  autoRegisteredModule(data): Observable<any> {
    return this.http.post<any>(this.modulePathRegistration,data);
  }
  getUserModules(data) :Observable<JoWebuserRolesVm[]>  {
    return this.http.post<JoWebuserRolesVm[]>(this.moduleUserModule,data);
  }


  logOut() {
    this.isAuthentic = false;
    localStorage.removeItem('token');
    localStorage.removeItem('defaultCompany');
    localStorage.removeItem('defaultDivision');
    localStorage.removeItem('joUType');
    localStorage.removeItem('currentUser');
    localStorage.removeItem('linemanCode');
    localStorage.removeItem('fullName');


    this.sharedProvinceAndMunicipality  = [];
    this.authChange.next(false);
    this.joWebuserRolesVm.next([]);
    this.getUserType();
    this.resetUserModule();
    this.router.navigate(["home"]);
  }

  saveToken(token) {
    this.isAuthentic = true;
    this.authChange.next(true);
    localStorage.setItem('token', token)
  }

  saveDefaultActiveCompany(companyCode, divisions, userType, currentUser, provinces, municipalities, municipalityArray,linemanCode, fullName, tranTypeCodes, locationCodes) {
    if (tranTypeCodes == null || tranTypeCodes == undefined) {
      tranTypeCodes = 'ALL';
    }
    if (locationCodes == null || locationCodes == undefined) {
      locationCodes = 'ALL';
      //locationCodes = '00013,00019';
    }
    localStorage.setItem('defaultCompany',companyCode);
    localStorage.setItem('defaultDivision',divisions);
    localStorage.setItem('joUType',userType);
    localStorage.setItem('currentUser',currentUser);
    localStorage.setItem('linemanCode',linemanCode);
    localStorage.setItem('fullName',fullName);
    localStorage.setItem('tranTypeCodes',tranTypeCodes);
    localStorage.setItem('locationCodes',locationCodes);
    if(municipalityArray.length > 0) {
       this.sharedProvinceAndMunicipality = municipalityArray;
    }
    else {
      const muns = municipalities.split(',');
      const provs = provinces.split(',');
      var rec = 0;
      muns.forEach(element => {
        this.sharedProvinceAndMunicipality.push({provinceCode: provs[rec], municipalityCode: element});
        if (rec+1 < provs.length) { rec ++;}
      });
    }
    
    this.getUserType();
  }

  processprovinces(params) {
    var provinces = "";
    const uniqueProvince = [...new Set( params.map(obj => obj.provinceCode)) ];
    uniqueProvince.forEach(element => {
      provinces+= element+ ',';
    });
    
    return provinces;
  }
  setDefaultCompanyCode(data) {
    this.defaultCompanyCode.next(data);
  }

  setCompanyName(data) {
    this.companyName.next(data);
  }
  setCurrentUser(data) {
    this.currentUser.next(data);
  }
  setJoWebuserRolesVm(data) {
    this.joWebuserRolesVm.next(data);
    this.initializeRole(data);
  }
  receivedJoWebuserRolesVm(): Observable<JoWebuserRolesVm[]> {
    return this.joWebuserRolesVm.asObservable();
  }
 
  setJoReassign(data) {
    this.isJoReassign.next(data == 'JOREASSIGN');
  }
  setJoReassignToCrew(data) {
    this.isJoReassignToCrew.next(data == 'JOREASSIGNTOCREWSERVICE');
  }
  setJoReassignToCrewApplyMl(data) {
    this.isJoReassignToCrewApplyMl.next(data == 'JOREASSIGNTOCREWAPPLYML');
  }
  setJoRelease(data) {
    this.isJoRelease.next(data == 'JORELEASE');
  }

  setJoClose(data) {
    this.isJoClose.next(data == 'JOCLOSING');
  }
  setJoChangeNnP(data) {
    this.isJoChangeNnP.next(data == 'JOCHANGENAPPORT');
  }

  setJoRemarks(data) {
    this.isJoRemarks.next(data == 'JOUPDATEREMARKS');
  }
  setJoReschedule(data) {
    this.isJoReSchedule.next(data == 'JORESCHEDULE');
  }
  setActivateCpe(data) {
    this.isActivateCpe.next(data == 'ACTIVATECPE');
  }
  setTerminateOnt(data) {
    this.isTerminateOnt.next(data == 'TERMINATEONT');
  }
  setRefreshCpe(data) {
    this.isTerminateOnt.next(data == 'REFRESHCPE');
  }
  setAssignCpe(data) {
    this.isActivateCpe.next(data == 'ASSIGNCPE');
  }
  setServiceCall(data) {
    this.isServiceCall.next(data == 'SERVICECALL');
  }
  setNapLocAssignment(data) {
    this.isNapLocAssignment.next(data == 'NAPLOCASSIGNMENT');
  }
  setBulkTerminate(data) {
    this.isBulkTerminate.next(data == 'BULKTERMINATE');
  }
  setTDLifting(data) {
    this.isTDLifting.next(data == 'TDLIFTING');
  }

  setUserRights(data) {
    this.isUserRights.next(data == 'UserRights'.toUpperCase());
  }
  setNewUser(data) {
    this.isNewUser.next(data == 'NewUser'.toUpperCase());
  }
  setViewUsers(data) {
    this.isViewUsers.next(data == 'ViewUsers'.toUpperCase());
  }
  setOtrsViewer(data) {
    this.isOtrsViewer.next(data == 'OTRSVIEWER'.toUpperCase());
  }
  setViewNaps(data) {
    this.isViewNaps.next(data == 'NAPSVIEWER'.toUpperCase());
  }
  setSystemRole(data) {
    this.isSystemRole.next(data == 'SystemRole'.toUpperCase());
  }
  setModule(data) {
    this.isModule.next(data == 'Module'.toUpperCase());
  }
  setRolemodule (data) {
    this.isRolemodule.next(data == 'Rolemodule'.toUpperCase());
  }
  setUserModule (data) {
    this.isUserModule .next(data == 'UserModule'.toUpperCase());
  }
  setReports (data) {
    this.isReports.next(data == 'Reports'.toUpperCase());
  }
  setMaterialUsageReport (data) {
    this.isMaterialUsageReport.next(data == 'MaterialUsageReport'.toUpperCase());
  }
  
  setViewJoDetail(data) {
    this.isViewJoDetail.next(data == 'ViewJoDetail'.toUpperCase());
  }
  receiveDefaultCompanyCode() : Observable<string> {
    return this.defaultCompanyCode.asObservable();
  }
  receiveJoReassign() : Observable<boolean> {
    return this.isJoReassign.asObservable();
  }
  receiveJoReassignToCrew() : Observable<boolean> {
    return this.isJoReassignToCrew.asObservable();
  }
  receiveJoReassignToCrewApplyMl() : Observable<boolean> {
    return this.isJoReassignToCrewApplyMl.asObservable();
  }
  receiveJoRelease() : Observable<boolean> {
    return this.isJoRelease.asObservable();
  }
  receiveJoClose() : Observable<boolean> {
    return this.isJoClose.asObservable();
  }
  receiveJoChangeNnP() : Observable<boolean> {
    return this.isJoChangeNnP.asObservable();
  }

  receiveJoRemarks() : Observable<boolean> {
    return this.isJoRemarks.asObservable();
  }
  receiveJoReschedule() : Observable<boolean> {
    return this.isJoReSchedule.asObservable();
  }
  receiveActivateCpe() : Observable<boolean> {
    return this.isActivateCpe.asObservable();
  }
  receiveTerminateOnt() : Observable<boolean> {
    return this.isTerminateOnt.asObservable();
  }

  receiveRefreshCpe(): Observable<boolean> {
    return this.isRefreshCpe.asObservable();
  }
  receiveAssignCpe() : Observable<boolean> {
    return this.isAssignCpe.asObservable();
  }

  receiveServiceCall() : Observable<boolean> {
    return this.isServiceCall.asObservable();
  }
  receiveBulkTerminate() : Observable<boolean> {
    return this.isBulkTerminate.asObservable();
  }
  receiveTDLifting() : Observable<boolean> {
    return this.isTDLifting.asObservable();
  }
  receiveUserRights() : Observable<boolean> {
    return this.isUserRights.asObservable();
  }
  receiveNewUser() : Observable<boolean> {
    return this.isNewUser.asObservable();
  }
  receiveViewUsers() : Observable<boolean> {
    return this.isViewUsers.asObservable();
  }

  receiveSystemRole() : Observable<boolean> {
    return this.isSystemRole.asObservable();
  }
  receiveModule() : Observable<boolean> {
    return this.isModule.asObservable();
  }
  receiveRolemodule() : Observable<boolean> {
    return this.isRolemodule.asObservable();
  }
  receiveUserModule() : Observable<boolean> {
    return this.isUserModule.asObservable();
  }

  receiveReports() : Observable<boolean> {
    return this.isReports.asObservable();
  }
  receiveMaterialUsageReport() : Observable<boolean> {
    return this.isMaterialUsageReport.asObservable();
  }
  receiveViewJoDetail() : Observable<boolean> {
    return this.isViewJoDetail.asObservable();
  }
  receiveOtrsViewer() : Observable<boolean> {
    return this.isOtrsViewer.asObservable();
  }
  receiveNapsViewer() : Observable<boolean> {
    return this.isViewNaps.asObservable();
  }
  receiveNapLocAssignment() : Observable<boolean> {
    return this.isNapLocAssignment.asObservable();
  }


  getToken() {
    return localStorage.getItem('token')
  }
  getUserType(): boolean {
    if(localStorage.getItem('joUType') == null) {
      this.isCrew.next(true);
      this.isLineman.next(false);
      this.isJoAdmin.next(false);
      this.isAdmin.next(false);
      return false;
    }
    var isValidUserType =  false;
    var expression = localStorage.getItem('joUType').toUpperCase();
    switch(expression) { 
      case "CREW": { 
         this.isCrew.next(true);
         this.isLineman.next(false);
         this.isJoAdmin.next(false);
         this.isAdmin.next(false);
         this.isContractor.next(false);
         break; 
      } 
      case "LINEMAN": { 
         this.isLineman.next(true);
         this.isCrew.next(false);
         this.isJoAdmin.next(false);
         this.isAdmin.next(false);
         this.isContractor.next(false);
         break; 
      } 
      case "CONTRACTOR": { 
        this.isContractor.next(true);
        this.isLineman.next(false);
        this.isCrew.next(false);
        this.isJoAdmin.next(false);
        this.isAdmin.next(false);
        break; 
     } 
      case "JOADMIN": { 
        this.isJoAdmin.next(true);
        this.isLineman.next(false);
        this.isCrew.next(false);
        this.isAdmin.next(false);
        this.isContractor.next(false);
        break; 
     } 
     case "ADMIN": { 
      this.isAdmin.next(true);
      this.isJoAdmin.next(false);
      this.isLineman.next(false);
      this.isCrew.next(false);
      this.isContractor.next(false);
      break; 
     } 
      default: { 
         this.isLineman.next(true);
         this.isJoAdmin.next(false);
         this.isLineman.next(false);
         this.isCrew.next(false);
         break; 
      } 
   } 
         
    return isValidUserType;
  }

  getJoWebModules() {
    return this.joWebuserRolesVm;
  }
  isCanAccessModule(moduleName, accessModules) {
    var index = accessModules.findIndex(x => x.moduleName == moduleName);
    this.isModule.next(index >= 0);
  }
  isAuthenticated() {
      if (this.getToken()) {
        this.isAuthentic = true;
        return this.authChange.next(true);
      } else{
        this.isAuthentic = false;
        return this.authChange.next(false);
        }
      }

  isAuth() {
    return this.isAuthentic;
  }
  private initializeRole(data: JoWebuserRolesVm[] ) {
    data.forEach(module => {
      if(module.moduleName == 'userrights'.toUpperCase() ) 
       this.isUserRights.next(true);
      if(module.moduleName == 'viewUsers'.toUpperCase() ) 
      this.isViewUsers.next(true);
      if(module.moduleName == 'newuser'.toUpperCase() ) 
      this.isNewUser.next(true);
      if(module.moduleName == 'systemrole'.toUpperCase() ) 
      this.isSystemRole.next(true);
      if(module.moduleName == 'module'.toUpperCase() ) 
      this.isModule.next(true);
      if(module.moduleName == 'rolemodule'.toUpperCase() ) 
      this.isRolemodule.next(true);
      if(module.moduleName == 'usermodule'.toUpperCase() ) 
      this.isUserModule.next(true);
      if(module.moduleName == 'reports'.toUpperCase() ) 
      this.isReports.next(true);
      if(module.moduleName == 'materialusagereport'.toUpperCase() ) 
      this.isMaterialUsageReport.next(true);
      if(module.moduleName == 'viewJoDetail'.toUpperCase() ) 
      this.isViewJoDetail.next(true);
      if(module.moduleName == 'joreassign'.toUpperCase() ) 
      this.isJoReassign.next(true);
      if(module.moduleName == 'JOREASSIGNTOCREWSERVICE'.toUpperCase() ) 
      this.isJoReassignToCrew.next(true);
      if(module.moduleName == 'JOREASSIGNTOCREWAPPLYML'.toUpperCase() ) 
      this.isJoReassignToCrewApplyMl.next(true);
      if(module.moduleName == 'jorelease'.toUpperCase() ) 
      this.isJoRelease.next(true);
      if(module.moduleName == 'JOCHANGENAPPORT'.toUpperCase() ) 
      this.isJoChangeNnP.next(true);
      if(module.moduleName == 'joupdateremarks'.toUpperCase() ) 
      this.isJoRemarks.next(true);
      if(module.moduleName == 'ACTIVATECPE'.toUpperCase() ) 
      this.isActivateCpe.next(true);
      if(module.moduleName == 'TERMINATEONT'.toUpperCase() ) 
      this.isTerminateOnt.next(true);
      if(module.moduleName == 'ASSIGNCPE'.toUpperCase() ) 
      this.isAssignCpe.next(true);
      
      if(module.moduleName == 'JOCLOSING'.toUpperCase() ) 
      this.isJoClose.next(true);
      if(module.moduleName == 'JORESCHEDULE'.toUpperCase() ) 
      this.isJoReSchedule.next(true);
      if(module.moduleName == 'SERVICECALL'.toUpperCase() ) 
      this.isServiceCall.next(true);
      if(module.moduleName == 'BULKTERMINATE'.toUpperCase() ) 
      this.isBulkTerminate.next(true);
      if(module.moduleName == 'TDLIFTING'.toUpperCase() ) 
      this.isTDLifting.next(true);
      if(module.moduleName == 'NAPLOCASSIGNMENT'.toUpperCase() ) 
      this.isNapLocAssignment.next(true);
      if(module.moduleName == 'OTRSVIEWER'.toUpperCase() ) 
      this.isOtrsViewer.next(true);
      if(module.moduleName == 'NAPSVIEWER'.toUpperCase() ) 
      this.isViewNaps.next(true);
      if(module.moduleName == 'REFRESHCPE'.toUpperCase() ) 
      this.isRefreshCpe.next(true);
      
    })
  }
  private resetUserModule() {
    this.isUserRights.next(false);
    this.isViewUsers.next(false);
    this.isNewUser.next(false);
    this.isSystemRole.next(false);
    this.isModule.next(false);
    this.isRolemodule.next(false);
    this.isUserModule.next(false);
    this.isReports.next(false);
    this.isMaterialUsageReport.next(false);
    this.isViewJoDetail.next(false);
    this.isJoReassign.next(false);
    this.isJoReassignToCrew.next(false);
    this.isJoReassignToCrewApplyMl.next(false);
    this.isJoRelease.next(false);
    this.isJoClose.next(false);
    this.isJoChangeNnP.next(false);
    this.isJoRemarks.next(false);
    this.isActivateCpe.next(false);
    this.isTerminateOnt.next(false);
    this.isJoReSchedule.next(false);
    this.isServiceCall.next(false);
    this.isBulkTerminate.next(false);
    this.isTDLifting.next(false);
    this.isNapLocAssignment.next(false);
    this.isOtrsViewer.next(false);
    this.isViewNaps.next(false);
    this.isRefreshCpe.next(false);
  }
  private encrypt(input: string, encoding: string): string {
    try {
      let sbBytes = '';
  
      // Loop through each character and get the UTF-16 code unit (2 bytes per character)
      for (let i = 0; i < input.length; i++) {
        const code = input.charCodeAt(i);
        sbBytes += code.toString(16).toUpperCase().padStart(4, '0'); // UTF-16 uses 4 hex digits
      }
  
      return sbBytes;
    } catch (error) {
      return error.message;
    }
  }
  
}
interface LoginInterface {
  emailAddress: string;
  password: string;
}

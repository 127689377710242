import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from './auth.service';


@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(private authService: AuthService, private router: Router) { }
  appSubscription = new Subscription();
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.authService.isAuth()) {
      //this.appSubscription.add(this.authService.checkRegisteredModule(route.routeConfig.path.toUpperCase())
      //.subscribe(result => { 
      //  if (result.id == 0) 
      //    this.autoRegisterModule(route);
      //  }) );
       return true;
    } else {
      this.router.navigate(["login"]);
      return false;
    }
  }
}

import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ErrordialogComponent } from 'src/app/errordialog/errordialog.component';

@Injectable({
  providedIn: 'root'
})
export class ErrorDialogService {

  constructor(private dialog: MatDialog) { }
  openErrorDialog(msg) {
    return this.dialog.open(ErrordialogComponent, {
      width: '390px',
      panelClass: 'confirm-dialog-container',
      disableClose: true,
      // position: {top: '10px'},
      data : {
        message: msg
      }
    });
  }
}
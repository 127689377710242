import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { ErrorDialogService } from 'src/app/shared/services/errordialog.service';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit , OnDestroy{
  loginForm: FormGroup;
  isLoading = true;
  hide = true;
  userName = "";
  passwordEntered = "";
  private appSubscription = new Subscription();
  constructor(private fb: FormBuilder, private authService: AuthService,
     private router: Router, private errorDialogService: ErrorDialogService ) { 
    this.loginForm = this.fb.group({
      emailAddress: ['', Validators.required],
      password: ['', Validators.required]
    })
  }

  ngOnInit() {
    this.isLoading = false;
  }
  ngOnDestroy() {
    this.appSubscription.unsubscribe();
  }

  login() {
    this.isLoading = true;
    if (!this.validateLogin()) {
      this.isLoading = false;
      return;
    }
    this.isLoading = false;

    this.userName = this.loginForm.get('emailAddress').value;
    this.userName = this.userName.toUpperCase().trim();
    this.passwordEntered = this.loginForm.get('password').value;
    let param = {"emailAddress":this.userName, "password":this.passwordEntered};

    this.appSubscription.add(this.authService.login(param).subscribe(data => {
      if(data) {
        this.authService.saveToken(data.id);
        this.authService.saveDefaultActiveCompany(data.companyCode, data.divisions, data.joMobileUserType,data.emailAddress, data.provinces, data.municipalities, data.provinceMunicipality,data.linemanCode,data.fullName, data.tranTypeCodes, data.locationCodes);
        this.authService.setCompanyName('Converge ICT');
        this.authService.getUserType();
        this.authService.setCurrentUser(data.emailAddress);
        this.authService.setDefaultCompanyCode(data.companyCode);
        this.populateRoles();
      }
      else {
        this.errorDialogService.openErrorDialog('Invalid login attempt.');
        return;
      }
    })),
   ( error: any) => this.isLoading = false;
  }
  private populateRoles() {
    this.appSubscription.add(this.authService.getUserModules(this.loginForm.value)
    .subscribe(data => {
      if(data.length > 0) {
        if ( this.passwordEntered.toUpperCase() == 'CONVERGE') {
          this.errorDialogService.openErrorDialog('Changing password is required before gaining access to your assigned rights.');
        }
        else {
          this.authService.setJoWebuserRolesVm(data);};
          this.router.navigate(['dashboard']);
        }
        
      })
    );
    
  }
  validateLogin(): boolean {
    if (!environment.production && this.loginForm.get('emailAddress').value == "" ) {
      this.loginForm.controls['emailAddress'].setValue('BONGGUIAO-GPONADMIN@JOWEB.COM');
      this.loginForm.controls['password'].setValue('Default12312!');
    }

    if (this.loginForm.value.emailAddress == "" || !this.loginForm.value.emailAddress )
      return false;
    if (this.loginForm.value.password == "" || !this.loginForm.value.password )
      return false;
    return true;
  }
  register() {
      this.router.navigate(['register'])
  }
 
  get emailAddresss() {
    return this.loginForm.get('emailAddress')
  }

  get password() {
    return this.loginForm.get('password')
  }

}


import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'IBAS-JoWeb-Client-App';

  changeCompany() {
    alert('Company Change event');
  }
}

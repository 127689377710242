<mat-toolbar color="primary">
  <div fxHide-gt-xs>
      <button mat-icon-button (click)="onToggleSidenav()">
          <mat-icon>menu</mat-icon>
      </button>
  </div>
  <div>
    <a routerLink="/home">
    <img src="\assets\img\logo-converge.png" height="70px" width="190px">
    
    </a>
  </div>
  <span class="p-style">JoWeb</span>
  <span class="p-stylet">{{title}}</span>

  <!-- <span style="padding-top: 0px; padding-left: 40px; padding-right: 30px; background-color: rgb(45, 165, 81); color: rgb(243, 236, 233); border-radius: 10px; font-size: medium;">{{title}}</span> -->
  <span class="header-spacer"></span>
    
  <ul class="header-menu-layout navigation-items">
      <li *ngIf="isAuthenticated">
        <button *ngIf="isJoRelease || isJoReassign || isActivateCpe || isJoRemarks || isJoClose || isJoChangeNnP || isReSchedule || isServiceCall || isTerminateOnt || isNapLocAssignment || isAssignCpe || isActivateCpe || isJoReassignToCrew || isRefresCpe" mat-button [matMenuTriggerFor]="jomobile">Transactions</button>
        <mat-menu #jomobile="matMenu" yPosition="below">
          <button  *ngIf="isJoReassign" class="menu-color" mat-menu-item [routerLink]="['/joreassign']" (click)="changeTitle('Assign Job Order')">Assign Job Order</button>
          <button  *ngIf="isJoReassignToCrew" class="menu-color" mat-menu-item [routerLink]="['/joassigntocrewjom/mainlineapplication']" (click)="changeTitle('JO Assign to Crew - New App')">JO Assign to Crew - New App</button>
          <button  *ngIf="isJoReassignToCrew" class="menu-color" mat-menu-item [routerLink]="['/joassigntocrewresetpaymodejom/mainlineapplication']" (click)="changeTitle('JO CREW Reset Paymode - New App')">JO CREW Reset Paymode - New App</button>
          <button  *ngIf="isJoReassignToCrew" class="menu-color" mat-menu-item [routerLink]="['/joassigntocrew/servicecall']" (click)="changeTitle('JO Assign to Crew - Service')">JO Assign to Crew - Service</button>
          <button  *ngIf="isJoRelease" class="menu-color" mat-menu-item [routerLink]="['/jorelease']" (click)="changeTitle('Release Job Order')">Release Job Order</button>
          <button  *ngIf="isJoClose" class="menu-color" mat-menu-item [routerLink]="['/joclose/regular']" (click)="changeTitle('Jo Closing - Regular')">Jo Closing - Regular</button>
          <button  *ngIf="isJoClose" class="menu-color" mat-menu-item [routerLink]="['/joclose/conditional']" (click)="changeTitle('Jo Closing - Conditional')">Jo Closing - Conditionally Closed</button>
          <button  *ngIf="isJoChangeNnP" class="menu-color" mat-menu-item [routerLink]="['/jochangenapport']" (click)="changeTitle('Change Nap & Port')">Change Nap & Port</button>
          <button  *ngIf="isJoRemarks" class="menu-color" mat-menu-item [routerLink]="['/joremarks']" (click)="changeTitle('Job Order Remarks')">Job Order Remarks</button>
          <button  *ngIf="isJoReSchedule" class="menu-color" mat-menu-item [routerLink]="['/joreschedule']" (click)="changeTitle('Reschedule JO')">Reschedule JO</button>
          <button  *ngIf="isAssignCpe" class="menu-color" mat-menu-item [routerLink]="['/assignCpe']" (click)="changeTitle('Assign/UnAssign CPE')">Assign/UnAssign CPE</button>
          <button  *ngIf="isActivateCpe" class="menu-color" mat-menu-item [routerLink]="['/activatecpe']" (click)="changeTitle('Activate CPE')">Activate CPE</button>
          <button  *ngIf="isTerminateOnt" class="menu-color" mat-menu-item [routerLink]="['/terminateONT']" (click)="changeTitle('Terminate ONT')">Terminate ONT</button>
          <button  *ngIf="isRefershCpe" class="menu-color" mat-menu-item [routerLink]="['/refreshcpe']" (click)="changeTitle('Refresh CPE')">Refresh CPE</button>
          <!-- <button  *ngIf="isBulkTerminate" class="menu-color" mat-menu-item [routerLink]="['/batchTerminate']" (click)="changeTitle('Terminate Pulledout GPON ONT')">Terminate Pulledout GPON ONT</button> -->
          <button  *ngIf="isJoRemarks" class="menu-color" mat-menu-item [routerLink]="['/canceljo']" (click)="changeTitle('Cancel Job Order')">Cancel Job Order</button>
          <button  *ngIf="isServiceCall" class="menu-color" mat-menu-item [routerLink]="['/joServiceCall']" (click)="changeTitle('Service Call')">Service Call</button>
          <button  *ngIf="isNapLocAssignment" class="menu-color" mat-menu-item [routerLink]="['/naplocassignment']" (click)="changeTitle('Naploc Assignment')">Naploc Assigment</button>
         </mat-menu>
      </li>
      <li *ngIf="isAuthenticated && isAdmin && isLineMan">
        <a routerLink="/fileupload"  (click)="changeTitle('Street Map')">Fileupload</a>
      </li>
      <li *ngIf="isAuthenticated && isContractor && isLineMan && isJoAdmin">
          <a routerLink="/geolocation"  (click)="changeTitle('Street Map')">Map</a>
      </li>
      <li *ngIf="isAuthenticated">
        <button *ngIf="isViewJoDetail || isOtrsViewer || isViewNaps" mat-button [matMenuTriggerFor]="views">Views</button>
        <mat-menu #views="matMenu" yPosition="below">
          <button *ngIf="isViewJoDetail" class="menu-color" mat-menu-item [routerLink]="['/viewmodule/viewJoDetail']" (click)="changeTitle('View Job Order')">View Job Order</button>
          <button *ngIf="isViewNaps" class="menu-color" mat-menu-item [routerLink]="['/viewmodule/viewNap']" (click)="changeTitle('View NAPs')">View Naps</button>
          <button *ngIf="isViewJoDetail" class="menu-color" mat-menu-item [routerLink]="['/viewmodule/viewCollection']" (click)="changeTitle('Inhouse Account Collection')">Inhouse Account Collection</button>
        </mat-menu>
      </li>
      <li *ngIf="isAuthenticated">
      <button *ngIf="isReports" mat-button [matMenuTriggerFor]="reports">Reports</button>
      <mat-menu #reports="matMenu" yPosition="below">
        <button *ngIf="isReports" class="menu-color" mat-menu-item [routerLink]="['/reports/joReport']" (click)="changeTitle('Job Order Report')">Job Order Report</button>
        <button  *ngIf="isOtrsViewer" class="menu-color" mat-menu-item [routerLink]="['/jootrs']" (click)="changeTitle('OTRS Job Order')">OTRS Job Order</button>
        <button *ngIf="isMaterialUsageReport" class="menu-color" mat-menu-item [routerLink]="['/reports/materialUsage']" (click)="changeTitle('Material Usage')">Material Usage</button>
        <button *ngIf="isMaterialUsageReport" class="menu-color" mat-menu-item [routerLink]="['/reports/materialSerialized']" (click)="changeTitle('Material Usage - Serialized')">Material Usage - Serialized</button>
        <button *ngIf="isMaterialUsageReport" class="menu-color" mat-menu-item [routerLink]="['/reports/corporateListing']" (click)="changeTitle('Corporate Listing')">Corporate Listing</button>
      </mat-menu>
    </li>
    <!-- // && isAdmin"> -->
      <li *ngIf="isAuthenticated"> 
          <button *ngIf="isUserRights || isViewUsers || isNewUser ||isSystemRole ||isModule ||isRolemodule || isUserModule" mat-button [matMenuTriggerFor]="rights">User Management</button>
          <mat-menu #rights="matMenu" yPosition="below">
            <button  *ngIf="isUserRights" class="menu-color" mat-menu-item [routerLink]="['/userrights']" (click)="changeTitle('Set Multiple Division')">Set Multiple Division</button>
            <button  *ngIf="isViewUsers" class="menu-color" mat-menu-item [routerLink]="['/viewmodule/viewUsers']" (click)="changeTitle('Edit JoMobile Users')">Edit JoMobile Users</button>
            <button  *ngIf="isNewUser" class="menu-color" mat-menu-item [routerLink]="['/newuser']" (click)="changeTitle('New User')">New User</button>
            <button  *ngIf="isSystemRole" class="menu-color" mat-menu-item [routerLink]="['/systemrole']" (click)="changeTitle('System Role')">System Role</button>
            <button  *ngIf="isModule" class="menu-color" mat-menu-item [routerLink]="['/module']" (click)="changeTitle('System Module')">System Module</button>
            <button  *ngIf="!isRolemodule" class="menu-color" mat-menu-item [routerLink]="['/rolemodule']" (click)="changeTitle('System Role and Module')">System Role Module</button>
            <button  *ngIf="!isUserModule" class="menu-color" mat-menu-item [routerLink]="['/usermodule']" (click)="changeTitle('Users Module')">User's Module</button>
            <button  *ngIf="!isUserModule" class="menu-color" mat-menu-item [routerLink]="['/agent']" (click)="changeTitle('Agent Upload')">Agent Upload</button>
          </mat-menu>
      </li>
      <li *ngIf="isAuthenticated">
        <button mat-button>{{currentUser}}-{{defaultCompany}} </button>
      </li>
      <li *ngIf="isAuthenticated">
          <button mat-button (click)="onLogout()">Logout</button>
      </li>
      <li *ngIf="!isAuthenticated">
          <a routerLink="/login">Login</a>
      </li>
      <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu" style="padding-top: -20px;" *ngIf="isAuthenticated">
          <mat-icon style="background-color:cornflowerblue;">more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu" >
        <button mat-menu-item>
          <mat-icon matBadge="" matBadgeColor="warn"  matBadgePosition="before" matBadgeSize="small">notifications</mat-icon>
          <span (click)="changeTitle('Notification')">{{defaultCompany}}</span>
        </button>
        <button mat-menu-item>
          <mat-icon>password</mat-icon>
          <span [routerLink]="['/changepassword']" (click)="changeTitle('Change Password')">Change Password</span>
        </button>

        <button mat-menu-item>
          <mat-icon>dialpad</mat-icon>
          <span [routerLink]="['/dashboard']" (click)="changeTitle('Dash Board')">Dash Board</span>
        </button>
        <button mat-menu-item>
          <span>
              <button mat-menu-item [matMenuTriggerFor]="more">Settings</button>
            </span>
        </button>
        
      </mat-menu>
      <mat-menu #more="matMenu" yPosition="below">
        <button mat-menu-item>
          <span [routerLink]="['/recordconfiguration']" (click)="changeTitle('Settings')">Transaction</span>
        </button>
        <button mat-menu-item>
          <span [routerLink]="['/changeCompany']" (click)="changeTitle('Change Company Default')">Change Company Default</span>
        </button>
      </mat-menu>
      
  </ul>
</mat-toolbar>

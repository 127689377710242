<section style="height: max-content">
  <mat-sidenav-container>
    <mat-sidenav #navside role="navigation">

    </mat-sidenav>
    <mat-sidenav-content>
      <app-header></app-header>
      <main>
        <app-progress>
          
        </app-progress>
        <router-outlet>
        </router-outlet>
      </main>
    </mat-sidenav-content>
  </mat-sidenav-container>

</section>


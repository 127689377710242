<mat-card class="form-container">
    <mat-card-header>
        <div mat-card-avatar class="example-header-image"></div>
        <mat-card-title>CONVERGE ICT</mat-card-title>
        <mat-card-subtitle>User Registration</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>

        <form  class="example-form" [formGroup]="registerForm" (ngSubmit)="register()">
            <mat-form-field class="example-full-width">
                <mat-label>User Name</mat-label>
                <input matInput formControlName="userName" placeholder="user name" value="">
            </mat-form-field>

            <mat-form-field class="example-full-width">
                <mat-label>Email</mat-label>
                <input matInput formControlName="email" placeholder="email" value="">
            </mat-form-field>

            <mat-form-field class="example-full-width">
                <mat-label>Password</mat-label>
                <input matInput type="password" formControlName="password" value="bongguiao123">
            </mat-form-field>

            <button mat-flat-button color="primary">Submit</button>
        </form>

    </mat-card-content>
</mat-card>

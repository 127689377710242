import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AgentComponent } from './agentfile/agentfile.component';
import { LoginComponent } from './auth/login/login.component';
import { RegisterComponent } from './auth/register/register.component';
import { AuthGuardService } from './auth/services/auth-guard.service';
import { LandingComponent } from './landing/landing.component';


const routes: Routes = [
  { path:'login',component: LoginComponent},
  { path:'register',component: RegisterComponent},
  { path:'dashboard',component: LandingComponent , canActivate:[AuthGuardService]},
  { path:'agent',component: AgentComponent , canActivate:[AuthGuardService]},
  { path:'home',loadChildren: () => import('../app/home/home.module')
      .then(e=>e.HomeModule),canActivate: [AuthGuardService]},
   { path:'joreassign',loadChildren: () => import('../app/jomobile/jomobile.module')
      .then(e=>e.JomobileModule),canActivate: [AuthGuardService]},
  { path:'jorelease',loadChildren: () => import('../app/jomobile/jomobile.release.module')
      .then(e=>e.JomobileReleaseModule),canActivate: [AuthGuardService]},
  { path:'joclose/:id',loadChildren: () => import('../app/jomobile/jomobile.close.module')
      .then(e=>e.JomobileCloseModule),canActivate: [AuthGuardService]},
  { path:'jochangenapport',loadChildren: () => import('../app/jomobile/jomobile.changenp.module')
      .then(e=>e.JomobileChangeNapPortModule),canActivate: [AuthGuardService]},
  { path:'joremarks',loadChildren: () => import('../app/jomobile/jomobile.remarks.module')
      .then(e=>e.JoRemarkModule),canActivate: [AuthGuardService]},
  { path:'joreschedule',loadChildren: () => import('../app/jomobile/jomobile.reschedule.module')
      .then(e=>e.JoReScheduleModule),canActivate: [AuthGuardService]},
  { path:'activatecpe',loadChildren: () => import('../app/jomobile/jomobile.cpeactivation.module')
      .then(e=>e.JomobileCPEActivationModule),canActivate: [AuthGuardService]},
  { path:'canceljo',loadChildren: () => import('../app/jomobile/jocancel.module')
      .then(e=>e.JoCancelModule),canActivate: [AuthGuardService]},     
  { path:'assignCpe',loadChildren: () => import('../app/jomobile/jomobile.changecpe.module')
      .then(e=>e.JomobileChangeCPEModule ),canActivate: [AuthGuardService]},  
  { path:'joServiceCall',loadChildren: () => import('../app/jomobile/joservcall.module')
      .then(e=>e.JoServCallModule ),canActivate: [AuthGuardService]},   
  { path:'naplocassignment',loadChildren: () => import('../app/jomobile/naploc.module')
      .then(e=>e.NapLocModule ),canActivate: [AuthGuardService]},  
  { path:'terminateONT',loadChildren: () => import('../app/jomobile/joterminateont.module')
      .then(e=>e.JoTerminateOntModule ),canActivate: [AuthGuardService]},           
          
  { path:'userrights',loadChildren: () => import('../app/userrights/userrights.module')
      .then(e=>e.UserrightsModule),canActivate: [AuthGuardService]},
  { path:'newuser',loadChildren: () => import('../app/lineman/linemanadd.module')
      .then(e=>e.LinemanAddModule),canActivate: [AuthGuardService]},
  { path:'jouser/edit/:id',loadChildren: () => import('../app/lineman/edit/lineman-edit.module')
      .then(e=>e.LinemanEditModule),canActivate: [AuthGuardService]},
  { path:'viewmodule',loadChildren: () => import('../app/views/views.module')
    .then(e=>e.ViewsModule),canActivate: [AuthGuardService]},
  { path:'jootrs',loadChildren: () => import('../app/jomobile/jootrs.module')
    .then(e=>e.JoOtrsModule),canActivate: [AuthGuardService]}, 

  { path:'systemrole',loadChildren: () => import('../app/roles/roles.module')
    .then(e=>e.JoWebRoleModule),canActivate: [AuthGuardService]},  
  { path:'module',loadChildren: () => import('../app/modules/modules.module')
    .then(e=>e.JoWebModulesModule),canActivate: [AuthGuardService]},  
  { path:'rolemodule',loadChildren: () => import('../app/rolemodules/rolemodule.module')
    .then(e=>e.JoWebRoleModulesModule),canActivate: [AuthGuardService]},    
  { path:'usermodule',loadChildren: () => import('../app/usermodules/usermodule.module')
    .then(e=>e.UserModuleModule),canActivate: [AuthGuardService]},   
  { path:'viewjoaccomplishment',loadChildren: () => import('../app/views/joaccomplishment/joaccomplishment.module')
      .then(e=>e.JoAccomplishmentModule),canActivate: [AuthGuardService]},
  { path:'reports',loadChildren: () => import('../app/reports/reports.module')
      .then(e=>e.ReportsModule),canActivate: [AuthGuardService]},
  { path:'fileupload',loadChildren: () => import('../app/fileupload/fileup.module')
      .then(e=>e.FileupModule),canActivate: [AuthGuardService]},
  { path:'recordconfiguration',loadChildren: () => import('../app/settings/setting.module')
      .then(e=>e.SettingModule),canActivate: [AuthGuardService]},
  { path:'changeCompany',loadChildren: () => import('../app/settings/company/company.module')
      .then(e=>e.CompanyModule),canActivate: [AuthGuardService]},
  { path:'changepassword',loadChildren: () => import('../app/settings/password.module')
      .then(e=>e.ChangePasswordModule),canActivate: [AuthGuardService]},
  { path:'joform',loadChildren: () => import('../app/jomobile/joform.module')
      .then(e=>e.JoFormModule),canActivate: [AuthGuardService]},  
  { path:'refreshcpe',loadChildren: () => import('../app/jomobile/refreshcpe.module')
      .then(e=>e.RefreshCpeModule),canActivate: [AuthGuardService]},  
  { path:'batchTerminate',loadChildren: () => import('../app/jomobile/batchterminate.module')
      .then(e=>e.BatchTerminateOntModule),canActivate: [AuthGuardService]},   
  { path:'joassigntocrew/:id',loadChildren: () => import('../app/jomobile/jotocrew.module')
      .then(e=>e.JoToCrewModule),canActivate: [AuthGuardService]},  

  { path:'joassigntocrewjom/:id',loadChildren: () => import('../app/jomobile/jotocrewjom.module')
        .then(e=>e.JoToCrewJomModule),canActivate: [AuthGuardService]},         
  { path:'', redirectTo:'login',pathMatch:'full'},

  { path:'joassigntocrewresetpaymodejom/:id',loadChildren: () => import('../app/jomobile/jotrcrewjom-rev.module')
    .then(e=>e.JoToCrewRevJomModule),canActivate: [AuthGuardService]},         
{ path:'', redirectTo:'login',pathMatch:'full'}
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { FixedSizeVirtualScrollStrategy } from "@angular/cdk/scrolling";

export const environment = {
  production: true,
  //apiUrl: "http://192.168.115.41:5001/", 
  //apiUrl: "https://192.180.10.253:8443/",
  waitWhenTerminate : 120000,
  waitWhenActivate :  180000,
  apiUrl: "https://joweb.convergeict.com/api/",
  mapbox:{
    accessToken: 'pk.eyJ1IjoibWxndWlhb2pyIiwiYSI6ImNraHg5Z3JmaTFsOTkycm4zNWptOHF3cWYifQ.uI2GOd3DfwOEtlIkln-KHQ'
  },
  isTerminateONTActivateButtonShow: false,
  isTerminateONTActivateCpeReplacementShow: false,
  isClearBrassSession: true,
  isTerminateOldCPE: true,
  isActivateOnly: true,
  isRefreshCpe:false,
  isShowUMPProperties: true,
  isEnabledActivationButton: true
 
};

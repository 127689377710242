import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpXsrfTokenExtractor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService implements  HttpInterceptor{

  constructor(public authService: AuthService ,private tokenExtractor: HttpXsrfTokenExtractor) {}
  private actions: string[] = ["POST", "PUT", "DELETE","GET"];
  private forbiddenActions: string[] = ["HEAD", "OPTIONS"];
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    var token =  this.authService.getToken(); //this.tokenExtractor.getToken();
    var permitted =  this.findByActionName(request.method, this.actions);
    var forbidden =  this.findByActionName(request.method, this.forbiddenActions);
    if (permitted !== undefined && forbidden === undefined && token !== null) {
        request = request.clone({ setHeaders: { "X-XSRF-TOKEN": token, Authorization: `Bearer ${token}`} });
    }
    return next.handle(request);
  }
  private findByActionName(name: string, actions: string[]): string {
    return actions.find(action => action.toLocaleLowerCase() === name.toLocaleLowerCase());
  }
}